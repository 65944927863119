<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-row justify="start">

            <div class="px-4" v-for="roomData in roomDatas" :key="roomData.roomID">
                <v-card width="200">

                    <v-card-title class="pas-title-card">{{roomData[0].category}}</v-card-title>
                    <v-row>
                        <v-col v-for="room in roomData" :key="room.roomID">
                            <div v-if="room.roomStatus">
                                <v-icon class="pas-icon" v-if="room.type == 'Single'">mdi-bed-queen-outline</v-icon>
                                <v-icon class="pas-icon" v-if="room.type == 'Double'">mdi-bed-king-outline</v-icon>
                            </div> 
                            <div v-else>
                                <v-icon class="pas-icon-available" v-if="room.type == 'Single'">mdi-bed-queen-outline
                                </v-icon>
                                <v-icon class="pas-icon-available" v-if="room.type == 'Double'">mdi-bed-king-outline
                                </v-icon>
                            </div>
                            <v-divider></v-divider>
                            <span class="px-2">{{room.roomNumber}}</span>
                        </v-col>
                    </v-row>
                </v-card>

            </div>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Dashboard",
        watch: {},
        components: {},
        data() {
            return {
                roomDatas: [],
                items: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        to: "/HomeStayDashboard",
                        exact: true
                    },
                    {
                        text: "Rooms",
                        disabled: true,
                        to: "/Hotel",
                        exact: true
                    }
                ],
                branchInformation: []

            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            async formData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );
                this.branchInformation = dashboardData.data;

                const availableData = await axios.get('Hotel/RoomStatusCountAsync', {
                    params: {
                        ShowCount: false
                    }
                })
                this.roomDatas = availableData.data

            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }

    .pas-icon {
        font-size: 4em !important;
    }

    .pas-title-card {
        text-transform: uppercase;
        background: #1976d2;
        color: white;
    }

    .pas-icon-available {
        color: green;
        font-size: 4em !important;

    }
</style>